@import '../../styles/vars';

.work {
  padding: 2rem 1rem;
  text-align: start;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: end;
  gap: 1rem;

  &-header {
    grid-column: 1 / 13;
    height: 55rem;
    max-height: 100%;
    width: 100%;
    background-position:top center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    box-shadow: inset 0 12rem 140rem $darkBlue;

    &--title {
      z-index: 1;
    }
  }
  
  &-summary {
    grid-column: 1 / 13;
    font-family: $secondType;
    font-size: $medium;
    padding-bottom: 2rem;
  }


  &-miniature, &-categories {
    grid-column: 7 / 13;
  }

  
  &-description {
    grid-column: 1 / 7;
    line-height: 120%;
    font-size: $desc;
    font-weight: $thin;
  }

  &-categories {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    
    &--cats, &--lang {
      font-weight: $bold;
      display: flex;
      gap: 0.5rem;
      text-transform: capitalize;
    }
    
    &--cats {
      &_list {
        display: flex;
        gap: 0.5rem;
      }
    }
    
    &--lang {
      justify-content: space-between;
      align-items: baseline;
      
      &_lang {
        font-weight: $regular;
        display: flex;
        gap: 0.5rem
      }
    }
  }

  &-imgs {
    grid-column: 1 / 13;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    // grid-auto-flow: dense;

    &--img {
      height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }
}

// img:has(.large) {
//   grid-column: 1 / 3;
// }

@media (max-width: 40em) {
  .work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &-header {
      max-height: 100vh;
      padding: 2rem 0 0;
    }

    &-categories {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 1rem;
      // align-items: stretch;
      // justify-content: flex-end;
      
      &--cats {
        &_list {
          flex-wrap: wrap;
        }
      }
      
      &--lang {
        display: flex;
        flex-direction: column;
        
        &_lang {
          flex-wrap: wrap;
          
        }
      }
    }
    &-imgs {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 25em) {
  .work {
    &-header {
      padding: 2rem 0;     
    }

  }
}