@import "../../styles/vars";

.mainlogo {
  max-width: 100%;
  position: relative;
  transform-origin: center center;  
  top: 20%
}

.antoine-logo-container {
  max-width: 100%;
  height: 100vh;
}

@media (max-width: 40em) {
  .mainlogo {
    transform: scale(2)
  }
} 