@use 'reset';

@import '../styles/vars';

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  background-color: $darkBlue;
  margin: 0;
  font-family: $mainType;
  color: $lightBlue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// FONTS
h2 {
  font-size: 1.5rem;
  font-weight: $black;
  letter-spacing: 0.1rem;
}

.title-1 {
  text-align: start;
  // font-size: 9rem;
  font-size: $big;
  font-weight: $black;
}

.black {
  font-weight: $black;
}
.bold {
  font-weight: $bold;
}

/* curtains canvas container */
.curtains-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

// custom scrollbar
html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-thumb {
  background-color: $lightBlue;
  border-radius: 0.5rem;
}

html::-webkit-scrollbar-track {
  background-color: $darkBlue;
}