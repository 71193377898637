@import "../../../styles/vars";

.planeComponent {
  position: relative;
  height: $workItemHeight;
  
  &-container {
    height: 100%;
    position: absolute;
    // inset: 0;
    top: -10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    
    &_plane {
      z-index: -1;
      width: 120vw;
      position: absolute;
      // inset: 0;
      top: 10px;
      bottom: -20px;
      left: -100px;
      right: 10px;
  
      & > img {
        display: none;
      }
    }
  }
}

@media (max-width: 40em) {
  .planeComponent {
    
    &-container {
      top: -10px;
      bottom: 10px;
      left: 10px;
      right: 10px;
      
      &_plane {
        left: 0;
        right:0;
    
      }
    }
  }
}