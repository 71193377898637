@import '../../styles/vars';

.navigation {
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  position: fixed;
  width: 100%;
  z-index: 20;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  height: 60px;
  // background-color: $darkBlue;
  // overflow: auto;

  &-logo {
    flex-grow: 1;
    &--img {
      max-height: 150%;
    }
  }
}

.active {
  text-decoration: underline;
}

@media (max-width: 40em) {
  .navigation {
    &-logo {
      flex-grow: 0;
    }
  }
}