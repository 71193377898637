@import "../../styles/vars";

:root {
  --small: clamp(0.8rem, 2vw + 0.8rem, 2rem);
  --title: clamp(1rem, 9vw + 0.5rem, 6rem);
}

.homegallery {
   
  &-item {
    position: relative;
    overflow: hidden;
    z-index: 10;
    color: $lightBlue;
    height: 5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 20rem 15rem #00000090;

    &:last-child {
      border-bottom: 2px solid $lightBlue;
    }

    &--headerblock {
      display: flex;
      align-items: baseline;
      gap: 1rem;
      width: 100%;
      background-color: $darkBlue;
      border-top: 2px solid $lightBlue;
      padding: 1.3rem 1rem 1.7rem;

      &_id {
        font-size: 1rem;
        font-weight: $bold;
      }

      &_title {
        font-family: $secondType;
        font-size: var(--small);
        text-align: start;
      }
      
      &_categories {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.9rem;
        flex-grow: 1;
        font-weight: $bold;
      }

      &_arrow {
        align-self: center;
        font-size: 2rem;
        text-align: end;
        margin-right: 2rem;
        transition: 0.2s;

        &:hover {
          rotate: 90deg
        }
      }
    }

    &--content {
      position: relative;
      height: calc(100% - 3rem);
      padding: 1rem;
      display: flex;
      flex-direction: column;

      &_summary {
        text-align: start;
        font-weight: $light;
        font-size: $small;
      }
            
      &_categories {
        display: flex;
        font-weight: $bold;
        gap: 1rem;
        z-index: 1;
        width: 100%;
        font-size: $desc;
      }

      &_link {
        padding: 0.7rem 1.4rem;
        border: 2px solid $lightBlue;
        border-radius: 50rem;
        text-align: start;
        width: fit-content;
        margin: 1rem 0;
        font-size: 0.9rem;
        font-weight: $bold;
        z-index: 20;

        &:hover {
          background-color: $lightBlue;
          color: $darkBlue
        }
      }
    }

  }

  // HOVER ITEM
  &-hover {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: fixed;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    top: 0;
    left: 0;
    background-color: $lightBlue;
    border: 2px solid $lightBlue;
    opacity: 0;
  }
}

@media (max-width: 50em) {
  .homegallery {
    &-item {
      &--headerblock {
        &_title {
          flex-grow: 1;
        }
        
        &_categories {
          display: none;
        }
      }
    }
  }
}


@media (max-width: 40em) {
  .homegallery {
    &-item {
      max-height: 100vh;
      height: 4rem;

      &--content {            
        &_categories {
          flex-wrap: wrap;
          line-height: 0.5rem;
        }
      }
      &--headerblock {
        &_arrow {
          margin-right: 0;
          font-size: 1rem;
      
        }
      }

    }
  }
}

@media (max-width: 25em) {
  .homegallery {
    &-item {
      
    }
  }
}