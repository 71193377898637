@import "../../../styles/_vars.scss";

.divin {
  width: 100%;
  padding: 2rem 1rem;
  text-align: start;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &-video {
    margin: 2rem 0 6rem;
    align-self: center;
    border: 1em solid $screen;
    border-radius: 10px;
    max-width: 100%;
  }


  &-header {
    box-shadow: inset 0 2rem 140rem 0 $darkBlue;
    height: $workItemHeight;
    background-position: center;
    background-size: contain;
  }

  &-summary {
    font-size: $medium;
    font-family: $secondType;
  }

  &-lang {
    display: flex;
    gap: 1rem
  }
}

// ANIMATION LOGO
.logo {
  opacity: 1;
  display: none;

  &-svg {
    max-width: 100%;
  }

  &-container {
    display: grid;
    place-items: center;
    gap: 2rem;

    &--logo {
      overflow: hidden;
      display: grid;
      place-items: center;
      position: relative;
      padding: 3rem 3rem 0;
    }
  }

  &-element, .loading-element {
    fill: #ffffff
  }

  &-div-element {
    opacity: 1;
  }
}

/* ------------------------------------------------ */
/* CATEGORY CONTAINER */
/* ------------------------------------------------ */
.categories {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(12, 1fr);
  align-items: flex-end;
}

.category-container {
  margin: 3rem 0 2rem;

  &:nth-child(odd) {
    grid-column: 1 / 7;
  }
  &:nth-child(even) {
    grid-column: 7 / 13;
  }

  &:last-child {
    grid-column: 4/11;
  }
}

@media (max-width: 70em) {
  .divin {
  padding: 3rem 1rem 0;
  }
  .logo {
    &-svg {
      max-width: 150%;
      height: 65vh;
    }
  }

}

@media (max-width: 40em) {

  .logo {
    &-svg {
      max-width: 300%;
      height: 50vh;
    }
  }

  .categories {
    grid-template-columns: repeat(1, 1fr);
  }

  .category-container:nth-child(odd), .category-container:nth-child(even) {
    grid-column: 1 / 2;
  }
}