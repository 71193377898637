.errorPlane {
  position: relative;
  padding: 3rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  
  &-container {
    &_plane {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
  
      & img {
        display: none;
      }
    }
  }
}