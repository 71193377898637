@import '../../styles/vars';

.App {
  text-align: center;

  &-transition {
  position: relative;
  overflow: hidden;
  width: 100vw;
  display: grid;
  place-items: center;
  height: 100vh;
  z-index: 100;
    
    &--panel {
      &-1 {
              position: absolute;
      inset: 0;
      top: 100%;
      width: 100vw;
      height: 100vw;
      background: $lightBlue;
      }
      
      &-2 {
              position: absolute;
      inset: 0;
      top: 100%;
      width: 100vw;
      height: 100vw;
      background: $darkBlue;
      }
    }
  }
}
