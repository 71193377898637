@import "../../styles/vars";

.about {
  padding: 3rem 1rem 2rem;
  text-align: left;
  
  &-title {
    font-size: $big;
    margin-bottom: 1rem;
  }
  &-text {
    font-family: $secondType;
    font-size: $small;
  }
  
  &-clients {
    padding: 2rem 0 1rem;
    font-size: 1.2rem;
    font-weight: $black;
    
    &--list {
      overflow-x: hidden;
      border-top: 2px solid $lightBlue;
      border-bottom: 2px solid $lightBlue;
      margin: 1rem 0;
      padding: 1rem 0;
      font-size: clamp(1rem, 3vw + 0.5rem, 2rem);
      font-weight: $bold;

      &_elements {
        display: flex;
        gap: 0.5rem;
        
        &-el {
          display: flex;
          min-width: max-content;
        }

        &-1, &-2 {
          width: max-content;
          gap: 2rem;
          display: flex;
          // flex-wrap: wrap;
        }
      }
      
    }
  }
}

@media (max-width: 40em) {
  .about {
    padding: 5rem 1rem 2rem;
  
    &-text {
      line-height: 2rem;
    }
  }
}