@import '../../../styles/vars';

.gsap-parallax {
  position: absolute;
  inset: 0;
  
  &-container {
    // margin: 2rem 0 0;
    overflow: hidden;
    height: 55rem;
    max-height: 100vh;
    position: relative;
    inset: 0;
  }
  
  &-image {
    box-shadow: $boxShadow;
    -webkit-box-shadow: $boxShadow;
    -moz-box-shadow: $boxShadow;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: padding-box;
    background-position: center;
    will-change: transform;
    height: 60rem;
  }
}

@media (max-width: 40em) {

}