@import "../../styles/vars";

.contacts {
  // margin-top: calc(-2rem);
  background-color: $darkBlue;
  z-index: 100;
  padding: 1rem 0 0;

  &-grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);

    &--left, &--center, &--right {
      text-align: start;
      padding: 1rem;
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
    }

    &--left {
      grid-column: 1 / 4;
    }
    &--center {
      grid-column: 5 / 7;
    }
    &--right {
      justify-content: space-between;
      grid-column: 8 / 10;
      text-align: end;
    }
  }
}

.copyright {
  font-size: 0.9rem;
  font-weight: $light;
  line-height: 1.1rem;
}

@media (max-width: 40em) {
  
  .contacts {
    
    &-grid {
      display: flex;
      flex-direction: column;

      &--left, &--center, &--right {
        padding: 1rem 0rem;
      }
      
      &--right {
        text-align: center;

      }
    }
  }

}
