// COLORS
$lightBlue: #2dd4c6;
$darkBlue: #030d13;
$screen: #051925;

// TEXT
$mainType: "Montserrat", sans-serif;
$secondType: "Cormorant Garamond", serif;

$thin: 100;
$light: 300;
$normal: 400;
$regular: 500;
$semibold: 600;
$bold: 700;
$black: 900;

$big: clamp(1rem, 9vw + 0.5rem, 9rem);
$medium: clamp(1rem, 10vw + 0.5rem, 5rem);
$small: clamp(1rem, 3vw + 1rem, 3rem);
$desc: clamp(1rem, 2vw + 0.5rem, 4rem);

// COMPONENTS STYLES
$workItemHeight: 35rem;
$boxShadow: 0 20rem 20rem -1rem rgba(0,0,0,0.74) inset;