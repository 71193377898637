@import '../../../styles/vars';

.initial-loading {
  position: relative;
  // background-color: $darkBlue;
  overflow: hidden;
  width: 100vw;
  display: grid;
  place-items: center;
  height: 100vh;
  z-index: 99;
  
  &--logo {
    position: absolute;
    inset: 0;
    z-index: 999;
  }
  
  &--panel {
    
    &_1 {
      position: absolute;
      inset: 0;
      top: 100%;
      width: 100vw;
      height: 100vw;
      background: $lightBlue;
    }
    
    &_2 {
      position: absolute;
      inset: 0;
      top: 100%;
      width: 100vw;
      height: 100vw;
      background: $darkBlue;
    }
  }
}

@media (max-width: 40em) {
  
}