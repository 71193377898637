@import "../../../styles/_vars.scss";

.formacoach {
  width: 100%;
  padding: 2rem 1rem;
  text-align: start;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &-video {
    margin: 2rem 0 6rem;
    align-self: center;
    border: 1em solid $screen;
    border-radius: 10px;
    max-width: 100%;
  }


  &-header {
    box-shadow: inset 0 0 140rem 0 $darkBlue;
    height: 30rem;
    background-position: center;
    background-size: contain;
  }

  &-content {
    max-width: 100%;

    &--logo {
      padding: 5rem 0;
      display: flex;
      justify-content: center;

      &_item {
        max-width: 60%;
      }
    }

    &--header {
      overflow: hidden;
      height: 50rem;
      position: relative;
    }

    &--desktops, &--signature {
      display: flex;

      &_image {
        max-width: 50%;
      }
    }

    &--mobile {
      display: flex;
      gap: -2rem;
      justify-content: center;

      &_image {
        max-height: 500px;
      }
    }
  }

  &-summary {
    font-size: $medium;
    font-family: $secondType;
  }

  &-lang {
    display: flex;
    gap: 1rem
  }
}


@media (max-width: 70em) {
  .formacoach-content {
    &--mobile {
      flex-wrap: wrap;
      gap: 2rem;
      
      &_image {
        max-height: 600px;
      }
    }
  }
}

@media (max-width: 50em) {
  .formacoach-content {
    &--logo {
      width: 100%;
    }
    &--desktops, &--signature {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_image {
        max-width: 100%;
      }
    }
  }
}