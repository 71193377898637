@import "../../styles/vars";


.homepage {
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  padding: 1rem 0;

  &-title {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    line-height: 0.9;
    background-color: $darkBlue;
    z-index: 2;
  }
}

@media (max-width: 40em) {
  .homepage {
    &-title {
      padding: 4rem 1rem 2rem;
    }
  }
}
